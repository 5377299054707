import axios from 'axios'
import { VERSION } from '../data/constants'

export default function apiClient(cookies) {
  const instance = axios.create({
    baseURL: process.env.API_URL,
  })

  let token
  instance.setJwtToken = newToken => {
    token = newToken
  }

  instance.interceptors.request.use(
    conf => {
      if (token || cookies) {
        conf.headers.Authorization = `Bearer ${token || cookies}`
      }
      if (VERSION) {
        conf.headers.Version = VERSION
      }
      return conf
    },
    error => Promise.reject(error)
  )

  instance.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error.response ? error.response.data : error)
  )
  return instance
}
