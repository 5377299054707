import { INVOICE_API } from '../data/apis'

const INVOICE_GET = '/user/INVOICE_GET'
const INVOICE_GET_SUCCESS = '/user/INVOICE_GET_SUCCESS'
const INVOICE_GET_FAIL = '/user/INVOICE_GET_FAIL'
const CLEAR_INVOICE_ERRORS = '/user/CLEAR_INVOICE_ERRORS'

const initialState = {
  loading: false,
  loaded: false,
  isRedirecting: false,
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INVOICE_GET:
      return {
        ...state,
        loading: true,
      }
    case INVOICE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isRedirecting: true,
        data: action.result,
      }
    case INVOICE_GET_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error.msg,
      }
    case CLEAR_INVOICE_ERRORS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export const clearError = () => ({
  type: CLEAR_INVOICE_ERRORS,
})

export const getInvoice = data => dispatch =>
  dispatch({
    types: [INVOICE_GET, INVOICE_GET_SUCCESS, INVOICE_GET_FAIL],
    promise: async client => {
      try {
        const response = await client.post(INVOICE_API, data)
        return response
      } catch (error) {
        setTimeout(() => dispatch(clearError()), 10000)
        throw error
      }
    },
  })
