import { createSelector } from 'reselect'
import { loginState } from './login'

const userState = state => state.user

export const userData = createSelector([userState], user => user.data)

export const isPremiumUser = createSelector(
  [userData],
  data => (data && data.subscription && data.subscription.is_vip) || false
)

export const userPayments = createSelector(
  [userData],
  data => (data && data.payments) || []
)

export const combinedDetails = createSelector(
  [userData, loginState],
  (user, login) => ({
    ...user,
    lastLogin: login.lastLogin,
  })
)

export const userSettings = createSelector(
  [userData],
  data => (data && data.settings) || []
)
