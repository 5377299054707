const TOGGLE_MODAL = 'cboxera/TOGGLE_MODAL'

const initialState = {
  show: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const showModal = show => ({
  type: TOGGLE_MODAL,
  show,
})
