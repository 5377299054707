import { USERINFO_API } from '../data/apis'

const LOAD = '/userInfo/LOAD'
const LOAD_SUCCESS = '/userInfo/LOAD_SUCCESS'
const LOAD_FAIL = '/userInfo/LOAD_FAIL'

const SET_UPDATED_SETTINGS = '/userInfo/SET_UPDATED_SETTINGS'

const RESET_USER = '/userInfo/RESET_USER'

const CLEAR_RESPONSES = '/userProfile/CLEAR_RESPONSES'

const initialState = {
  loading: false,
  loaded: false,
  data: {
    username: '',
    email: '',
    subscription: {
      is_vip: false,
      days: 0,
    },
    settings: [],
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error.msg,
      }
    case SET_UPDATED_SETTINGS:
      return {
        ...state,
        data: {
          ...state.data,
          settings: [
            ...((state.data.settings.length > 0 &&
              state.data.settings.filter(
                setting => setting.service !== action.payload.service
              )) ||
              []),
            action.payload,
          ],
        },
      }
    case RESET_USER:
      return {
        ...initialState,
      }
    case CLEAR_RESPONSES:
      return {
        ...state,
        result: '',
        error: '',
      }
    default:
      return state
  }
}

export const clearResponses = () => ({
  type: CLEAR_RESPONSES,
})

export const resetUserInfo = () => ({
  type: RESET_USER,
})

export const loadUserInfo = () => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: async client => {
    try {
      const response = await client.get(USERINFO_API)
      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  },
})

export const setUpdatedSettings = payload => ({
  type: SET_UPDATED_SETTINGS,
  payload,
})
