import { SAVED_LINKS_API } from '../data/apis'

const LOAD = '/savelinks/LOAD'
const LOAD_SUCCESS = '/savelinks/LOAD_SUCCESS'
const LOAD_FAIL = '/savelinks/LOAD_FAIL'
const DELETE_LINKS = '/savelinks/DELETE_LINKS'
const DELETE_LINKS_SUCCESS = '/savelinks/DELETE_LINKS_SUCCESS'
const DELETE_LINKS_FAIL = '/savelinks/DELETE_LINKS_FAIL'
const SAVE_LINKS = '/savelinks/SAVE_LINKS'
const SAVE_LINKS_SUCCESS = '/savelinks/SAVE_LINKS_SUCCESS'
const SAVE_LINKS_FAIL = '/savelinks/SAVE_LINKS_FAIL'
const RESET_STATE = '/savelinks/RESET_STATE'

const initialState = {
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  savedMsg: '',
  error: '',
  data: {
    results: [],
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error.msg,
      }
    case SAVE_LINKS:
      return {
        ...state,
        saving: true,
        saved: false,
        error: '',
        savedMsg: '',
      }
    case SAVE_LINKS_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        savedMsg: action.result.msg,
        error: '',
      }
    case SAVE_LINKS_FAIL:
      return {
        ...state,
        saving: false,
        saved: false,
        savedMsg: '',
        error: action.error.msg,
      }
    case DELETE_LINKS:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_LINKS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        data: action.result,
      }
    case DELETE_LINKS_FAIL:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error.msg,
      }
    case RESET_STATE:
      return {
        ...initialState,
        data: {
          results: [...state.data.results],
        },
      }
    default:
      return state
  }
}

export const resetState = () => ({
  type: RESET_STATE,
})

export const loadSavedLinks = () => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: async client => client.get(SAVED_LINKS_API),
})

export const saveLinks = data => dispatch =>
  dispatch({
    types: [SAVE_LINKS, SAVE_LINKS_SUCCESS, SAVE_LINKS_FAIL],
    promise: async client => {
      try {
        const response = await client.post(SAVED_LINKS_API, data)
        setTimeout(() => dispatch(resetState()), 4000)
        return response
      } catch (error) {
        setTimeout(() => dispatch(resetState()), 4000)
        throw error
      }
    },
  })

export const deleteSavedLinks = data => dispatch =>
  dispatch({
    types: [DELETE_LINKS, DELETE_LINKS_SUCCESS, DELETE_LINKS_FAIL],
    promise: async client => {
      try {
        const response = await client.put(SAVED_LINKS_API, data)
        setTimeout(() => dispatch(resetState()), 4000)
        return response
      } catch (error) {
        setTimeout(() => dispatch(resetState()), 4000)
        throw error
      }
    },
  })
