import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Spinner } from 'reactstrap'
import socket from '../../socket'
import {
  LOGIN,
  REGISTER,
  FAQS,
  TERMS,
  CONTACT_US,
  PREMIUM,
  HOST_STATUS,
  VERSION,
} from '../../data/constants'

const Footer = () => {
  const [loading, setLoading] = useState(true)
  const [online, setOnline] = useState(0)

  useEffect(() => {
    socket.on('online', ({ count }) => {
      setLoading(false)
      setOnline(count)
    })
  }, [])
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <h4>Links</h4>
            <ul>
              <li>
                <Link to={REGISTER}>
                  <i className="fas fa-user-plus" /> Register
                </Link>
              </li>
              <li>
                <Link to={LOGIN}>
                  <i className="fas fa-sign-in-alt" /> Login
                </Link>
              </li>
              <li>
                <Link to={HOST_STATUS}>
                  <i className="fas fa-signal" /> Hosts Status
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Support</h4>
            <ul>
              <li>
                <Link to={CONTACT_US}>
                  <i className="fas fa-envelope" /> Contact Us
                </Link>
              </li>
              <li>
                <Link to={FAQS}>
                  <i className="fas fa-question-circle" /> Faq's
                </Link>
              </li>
              <li>
                <Link to={TERMS}>
                  <i className="fas fa-info-circle" /> Terms Of Service
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Purchase</h4>
            <ul>
              <li>
                <Link to={PREMIUM}>
                  <i className="fab fa-cc-stripe" /> Stripe
                </Link>
              </li>
              <li>
                <Link to={PREMIUM}>
                  <i className="fab fa-paypal" /> Reseller
                </Link>
              </li>
              <li>
                <Link to={PREMIUM}>
                  <i className="fas fa-money-bill-alt" /> PayTM (IN)
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Info</h4>
            <ul>
              <li>
                <i className="fa fa-users pr-1"></i>Users Online:{' '}
                {loading ? <Spinner size="sm" color="secondary" /> : online}
              </li>
              <li>
                <i className="fa fa-star pr-1"></i>
                Version: {VERSION}
              </li>
              <li>
                Copyright &#169; 2014 - {`${new Date().getFullYear()}`} Cboxera.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
