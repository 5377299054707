import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import socket from '../../socket'
const appVersion = require('../../../package.json').version

const UpdatesModal = () => {
  const [values, setValue] = useState({
    isOpen: false,
    data: {
      title: '',
      body: '',
      canClose: false,
    },
  })

  const toggle = () => {
    setValue({
      ...values,
      isOpen: false,
    })
  }

  useEffect(() => {
    socket.on('updates', ({ data }) => {
      const { version } = data
      if ((version && appVersion !== version) || !version) {
        setValue({
          isOpen: true,
          data,
        })
      }
    })
  }, [])

  const {
    isOpen,
    data: { title, body, details, canClose },
  } = values
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {body}
        <br />
        {details && (
          <>
            <h6 className="text-danger">{details.title}</h6>
            <ul>
              {details.body.map((info, index) => (
                <li key={String(index)}>{info}</li>
              ))}
            </ul>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {canClose ? (
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        ) : (
          <Button
            color="danger"
            onClick={() => window && window.location.reload()}
          >
            Reload
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default UpdatesModal
