import {
  LOGIN,
  REGISTER,
  CONTACT_US,
  PREMIUM,
  HOST_STATUS,
  FAQS,
  ABOUTUS,
  DOWNLOADER,
  PROFILE,
} from './constants'

const privateMenu = [
  {
    id: 2,
    name: 'Downloader',
    link: DOWNLOADER,
    submenu: [],
    icon: 'fas fa-download',
  },
  {
    id: 3,
    name: 'My Profile',
    link: PROFILE,
    submenu: [],
    icon: 'fas fa-user-alt',
  },
]

const menu = [
  {
    id: 1,
    name: 'Home',
    link: '/',
    submenu: [],
    icon: 'fas fa-home',
  },
  {
    id: 4,
    name: 'Register',
    link: REGISTER,
    submenu: [],
    icon: 'fas fa-user-plus',
  },
  {
    id: 5,
    name: 'login',
    link: LOGIN,
    submenu: [],
    icon: 'fas fa-sign-in-alt',
  },
  {
    id: 6,
    name: 'Premium',
    link: PREMIUM,
    submenu: [],
    icon: 'fas fa-crown',
  },
  {
    id: 11,
    name: 'Support',
    link: CONTACT_US,
    submenu: [],
    icon: 'fas fa-envelope',
  },
  {
    id: 7,
    name: 'More',
    link: '/#',
    icon: 'fas fa-caret-down',
    submenu: [
      {
        id: 8,
        name: 'Host Status',
        link: HOST_STATUS,
        icon: 'fas fa-signal',
      },
      {
        id: 9,
        name: "Faq's",
        link: FAQS,
        icon: 'fas fa-question-circle',
      },
      {
        id: 10,
        name: 'About us',
        link: ABOUTUS,
        icon: 'fas fa-info-circle',
      },
    ],
  },
]

export { menu, privateMenu }
