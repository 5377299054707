import { VERIFY_API } from '../data/apis'

const VERIFY = '/user/VERIFY'
const VERIFY_SUCCESS = '/user/VERIFY_SUCCESS'
const VERIFY_FAIL = '/user/VERIFY_FAIL'

const initialState = {
  loading: false,
  loaded: false,
  isVerified: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VERIFY:
      return {
        ...state,
        loading: true,
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isVerified: true,
        response: action.result,
      }
    case VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      }
    default:
      return state
  }
}

export const userVerify = data => ({
  types: [VERIFY, VERIFY_SUCCESS, VERIFY_FAIL],
  promise: async client => {
    try {
      const response = await client.post(VERIFY_API, data)
      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  },
})
