module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"cboxera.com","short_name":"Cboxera","start_url":"/","background_color":"#f70156","theme_color":"#f70156","display":"minimal-ui","icon":"src/static/logo-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3f98e05accf1f876027c395dd5cb6e37"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f70156","showSpinner":true,"easing":"ease","speed":100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
