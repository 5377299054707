import { resetUserInfo } from '../user'
import { resetLoginState } from '../login'
import { showModal } from '../modal'

export default function userMiddleWare() {
  return ({ dispatch, getState }) => next => action => {
    const { type, error } = action
    if (
      (error && error.status === 'invalid token') ||
      (error && error.msg === 'No Authorization was found')
    ) {
      dispatch(showModal(true))
      dispatch(resetUserInfo())
      dispatch(resetLoginState())
    }
    if (type === '/user/LOGOUT_SUCCESS') {
      dispatch(resetUserInfo())
    }
    if (type === '/userInfo/LOAD_SUCCESS') {
      /* eslint-disable camelcase */
      const {
        result: { username, email },
      } = action
      const {
        login: { hash = '' },
      } = getState()
      try {
        const Tawk_API = (window && window.Tawk_API) || {}
        // eslint-disable-next-line func-names
        Tawk_API.onLoad = function () {
          Tawk_API.setAttributes(
            {
              name: username,
              email,
              hash,
            },
            err => console.log('tawk-to-error', err)
          )
        }
      } catch (err) {
        console.warn(err)
      }
    }
    return next(action)
  }
}
