import { REGISTER_API } from '../data/apis'

const REGISTER = '/user/REGISTER'
const REGISTER_SUCCESS = '/user/REGISTER_SUCCESS'
const REGISTER_FAIL = '/user/REGISTER_FAIL'

const initialState = {
  loading: false,
  loaded: false,
  isRegistered: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        loading: true,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isRegistered: true,
      }
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        isRegistered: false,
        error: action.error.errors,
      }
    default:
      return state
  }
}

export const registerUser = data => ({
  types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
  promise: async client => {
    try {
      const response = await client.post(REGISTER_API, data)
      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  },
})
