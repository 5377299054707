import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const CustomModal = ({ open, title, message, buttonName, onClick }) => (
  <Modal isOpen={open}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>{message}</ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onClick}>
        {buttonName}
      </Button>{' '}
    </ModalFooter>
  </Modal>
)

CustomModal.defaultProps = {
  open: false,
  title: '',
  message: '',
  buttonName: '',
  onClick: () => {},
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
}

export default CustomModal
