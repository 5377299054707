import { combineReducers } from 'redux'

import app from './app'
import login from './login'
import register from './register'
import verify from './verify'
import user from './user'
import generator from './generator'
import modal from './modal'
import invoice from './invoice'
import savedlinks from './savedlinks'

export default combineReducers({
  app,
  login,
  register,
  verify,
  user,
  generator,
  modal,
  invoice,
  savedlinks,
})
