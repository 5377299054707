export const { version: VERSION } = require('../../package.json')

export const HOME = '/'
export const REGISTER = '/register'
export const LOGIN = '/login'
export const PREMIUM = '/premium'
export const FORGOTPASS = '/forgot-password'

/* Private routes */
export const DOWNLOADER = '/user/downloader'
export const PROFILE = '/user/profile'

/* static pages */
export const FAQS = '/faqs'
export const PAYTM = '/paytm'
export const ABOUTUS = '/about'
export const TERMS = '/terms'
export const CONTACT_US = '/contact'
export const HOST_STATUS = '/host-status'

/* config */
export const REFRESH_INTERVAL = 10 * 60 * 1000
export const REMOUNT_INTERVAL = 2 * 1000
export const MAX_REFRESHES = 6
