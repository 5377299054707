const LOAD = '/app/LOAD'
const LOAD_SUCCESS = '/app/LOAD_SUCCESS'
const LOAD_FAIL = '/app/LOAD_FAIL'
const RESET_STATE = '/app/RESET_STATE'

const SYNC_DONE = '/app/SYNC_DONE'

const staticState = {
  loading: false,
  loaded: false,
  error: null,
}

const initialState = {
  app: {
    sync: false,
  },
  hosts: {
    ...staticState,
    data: [],
  },
  premiumkey: {
    ...staticState,
    data: {},
  },
  paymentoptions: {
    ...staticState,
    data: [],
  },
  resellers: {
    ...staticState,
    data: [],
  },
  forgotpassword: {
    ...staticState,
    data: {},
  },
  dlhistory: {
    ...staticState,
    data: {
      isActive: false,
      results: [],
    },
  },
  downloadinfo: {
    ...staticState,
    data: {
      dlink: '',
    },
  },
  resetpass: {
    ...staticState,
    data: {},
  },
  paymentstatus: {
    ...staticState,
    data: {},
  },
  changepassword: {
    ...staticState,
    data: {},
  },
  settings: {
    ...staticState,
    data: [],
  },
  updatesettings: {
    ...staticState,
    data: {},
  },
  bandwidth: {
    ...staticState,
    data: {
      bandwidth_info: [],
      reset_time: 0,
    },
  },
  contact: {
    ...staticState,
    data: {},
  },
  appState: {
    isLoaded: false,
  },
  folderLinks: {
    ...staticState,
    data: { result: { status: '', folderLinks: [] } },
  },
  fetchedLinks: {
    ...staticState,
    data: {
      result: { status: '', linksData: [], preferred: '' },
    },
  },
  deleteAccount: {
    ...staticState,
    data: {},
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: true,
          loaded: false,
          error: '',
          errorDetails: '',
        },
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
          loaded: true,
          data: action.result,
        },
      }
    case LOAD_FAIL:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
          loaded: true,
          error: action.error.msg,
          errorDetails: action.error,
        },
      }
    case RESET_STATE:
      return {
        ...state,
        [action.key]: {
          ...initialState[action.key],
        },
      }
    case SYNC_DONE:
      return {
        ...state,
        [action.key]: {
          sync: true,
        },
      }
    default:
      return state
  }
}

export const resetState = key => ({
  key,
  type: RESET_STATE,
})

export const getData = ({ key, api }) => ({
  key,
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: async client => {
    try {
      const response = await client.get(api)
      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  },
})

let interval
export const postData = (
  { key, api, clearErrors = true, clearApiReponse = false },
  data,
  successCallback = null,
  errorCallback = null,
  onendCallback = null
) => dispatch => {
  if (interval) {
    clearTimeout(interval)
    dispatch(resetState(key))
  }
  return dispatch({
    key,
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async client => {
      try {
        const response = await client.post(api, data)
        if (typeof successCallback === 'function') successCallback()
        return response
      } catch (err) {
        if (clearErrors)
          interval = setTimeout(() => dispatch(resetState(key)), 6000)
        if (typeof errorCallback === 'function') errorCallback()
        throw err
      } finally {
        if (clearApiReponse)
          interval = setTimeout(() => dispatch(resetState(key)), 10000)
        if (typeof onendCallback === 'function') onendCallback()
      }
    },
  })
}

export const setSync = () => ({
  key: 'app',
  type: SYNC_DONE,
})
