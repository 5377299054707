import axios from 'axios'
import { GENERATE_API, USERBANDWIDTH } from '../data/apis'

import { getData as getUserBandwidth } from './app'
const { CancelToken } = axios

let cancel

const GET_LINKS = '/generator/GET_LINKS'
const GENERATE = '/generator/GENERATE'
const GENERATE_SUCCESS = '/generator/GENERATE_SUCCESS'
const GENERATE_FAIL = '/generator/GENERATE_FAIL'
const SET_CURRENT_INDEX = '/generator/SET_CURRENT_INDEX'
const RESOLVE_CURRENT_INDEX = '/generator/RESOLVE_CURRENT_INDEX'
const IS_LEECHING_DONE = '/generator/IS_LEECHING_DONE'
const RETRY = '/generator/RETRY'
const RETRY_SUCCESS = '/generator/RETRY_SUCCESS'
const RETRY_FAIL = '/generator/RETRY_FAIL'

const RESET = '/generator/RESET'

const initialState = {
  loading: false,
  loaded: false,
  links: [],
  results: [],
  resolved: [],
  leeching: false,
  retryCount: 0,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GENERATE:
      return {
        ...state,
        loading: true,
        retryCount: 0,
        error: '',
      }
    case GENERATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        results: [...state.results, { ...action.result, isRetried: false }],
      }
    case GENERATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        results: [
          ...state.results,
          {
            error: true,
            isRetried: false,
            msg: 'Oops! Something went wrong. Try again later',
          },
        ],
        currentIndex: null,
        error: action.error.msg,
      }
    case SET_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.index,
      }
    case RESOLVE_CURRENT_INDEX:
      return {
        ...state,
        resolved: Array.from(new Set([...state.resolved, action.index])),
      }
    case IS_LEECHING_DONE:
      return {
        ...state,
        currentIndex: null,
        leeching: action.leeching,
      }
    case GET_LINKS:
      return {
        ...initialState,
        links: action.payLoad,
      }
    case RESET:
      return {
        ...initialState,
        leeching: false,
      }
    case RETRY:
      return {
        ...state,
        leeching: true,
        retryCount: state.retryCount + 1,
      }
    case RETRY_SUCCESS:
      return {
        ...state,
        leeching: false,
        results: [
          ...state.results.slice(0, state.currentIndex),
          { ...action.result, isRetried: true },
          ...state.results.slice(state.currentIndex + 1),
        ],
        currentIndex: null,
      }
    case RETRY_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        results: [
          ...state.results(0, state.currentIndex),
          {
            error: true,
            isRetried: false,
            msg: 'Oops! Something went wrong. Try again later',
          },
          ...state.results(state.currentIndex + 1),
        ],
        currentIndex: null,
        error: action.error.msg,
      }
    default:
      return state
  }
}

export const getLinks = payLoad => ({
  type: GET_LINKS,
  payLoad,
})

export const setCurrentIndex = index => ({
  type: SET_CURRENT_INDEX,
  index,
})

export const resolvecurrentIndex = index => ({
  type: RESOLVE_CURRENT_INDEX,
  index,
})

export const setLeechingStatus = leeching => ({
  type: IS_LEECHING_DONE,
  leeching,
})

export const resetEverything = () => ({
  type: RESET,
})

/* eslint-disable */

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

/* eslint-enable */

export const retryDownloadLink = (link, index) => async dispatch => {
  await dispatch(setLeechingStatus(true))
  await dispatch(setCurrentIndex(index))
  await dispatch({
    types: [RETRY, RETRY_SUCCESS, RETRY_FAIL],
    promise: async client => {
      try {
        const response = await client.post(GENERATE_API, { link })
        return response
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        await dispatch(resolvecurrentIndex(index))
      }
    },
  })
  dispatch(setLeechingStatus(false))
  dispatch(getUserBandwidth(USERBANDWIDTH))
}

export const getDownloadLink = links => async (dispatch, store) => {
  const {
    generator: { leeching },
  } = store
  if (leeching) {
    cancel('user cancelled request')
  }
  if (links && links.length === 0) return dispatch(resetEverything())
  await dispatch(getLinks(links))
  await dispatch(setLeechingStatus(true))
  await asyncForEach(links, async (link, index) => {
    await dispatch(setCurrentIndex(index))
    await dispatch({
      types: [GENERATE, GENERATE_SUCCESS, GENERATE_FAIL],
      promise: async client => {
        try {
          const response = await client.post(
            GENERATE_API,
            { link },
            {
              cancelToken: new CancelToken(c => {
                cancel = c
              }),
            }
          )
          return response
        } catch (error) {
          console.log(error)
          throw error
        } finally {
          await dispatch(resolvecurrentIndex(index))
        }
      },
    })
  })
  dispatch(setLeechingStatus(false))
  dispatch(getUserBandwidth(USERBANDWIDTH))
}
