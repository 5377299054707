// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-host-status-js": () => import("./../../../src/pages/host-status.js" /* webpackChunkName: "component---src-pages-host-status-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/payment-status.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-premium-js": () => import("./../../../src/pages/premium.js" /* webpackChunkName: "component---src-pages-premium-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-user-download-js": () => import("./../../../src/pages/user/download.js" /* webpackChunkName: "component---src-pages-user-download-js" */),
  "component---src-pages-user-downloader-js": () => import("./../../../src/pages/user/downloader.js" /* webpackChunkName: "component---src-pages-user-downloader-js" */),
  "component---src-pages-user-index-js": () => import("./../../../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-payment-status-js": () => import("./../../../src/pages/user/payment-status.js" /* webpackChunkName: "component---src-pages-user-payment-status-js" */),
  "component---src-pages-user-profile-js": () => import("./../../../src/pages/user/profile.js" /* webpackChunkName: "component---src-pages-user-profile-js" */),
  "component---src-pages-user-reset-password-js": () => import("./../../../src/pages/user/reset-password.js" /* webpackChunkName: "component---src-pages-user-reset-password-js" */),
  "component---src-pages-user-verify-js": () => import("./../../../src/pages/user/verify.js" /* webpackChunkName: "component---src-pages-user-verify-js" */),
  "component---src-templates-filehost-js": () => import("./../../../src/templates/Filehost.js" /* webpackChunkName: "component---src-templates-filehost-js" */)
}

