import Cookie from 'js-cookie'
import { LOGIN_API, LOGOUT_API } from '../data/apis'

const LOGIN = '/user/LOGIN'
const LOGIN_SUCCESS = '/user/LOGIN_SUCCESS'
const LOGIN_FAIL = '/user/LOGIN_FAIL'

const LOGOUT = '/user/LOGOUT'
const LOGOUT_SUCCESS = '/user/LOGOUT_SUCCESS'
const LOGOUT_FAIL = '/user/LOGOUT_FAIL'
const RESET_LOGIN_STATE = '/user/RESET_LOGIN_STATE'

const initialState = {
  loading: false,
  loaded: false,
  loggingOut: false,
  isLoggedIn: false,
  user: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isLoggedIn: true,
        accessToken: action.result.token,
        lastLogin: action.result.last_login,
        hash: action.result.hash,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error.msg,
      }
    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
        error: '',
      }
    case LOGOUT_SUCCESS:
      return initialState
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        error: action.error.msg,
      }
    case RESET_LOGIN_STATE:
      return {
        ...initialState,
        error: '',
      }
    default:
      return state
  }
}

const setAuth = client => token => {
  client.setJwtToken(token)
  Cookie.set('Authorization', token, { expires: 7 })
}

export const unsetAuth = client => {
  client.setJwtToken('')
  Cookie.remove('Authorization')
}

export const resetLoginState = () => ({
  type: RESET_LOGIN_STATE,
})

export const userLogin = data => dispatch =>
  dispatch({
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: async client => {
      try {
        const response = await client.post(LOGIN_API, data)
        setAuth(client)(response.token)
        return response
      } catch (error) {
        setTimeout(() => dispatch(resetLoginState()), 3000)
        throw error
      }
    },
  })

export const logout = () => dispatch =>
  dispatch({
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: async client => {
      try {
        const response = await client.post(LOGOUT_API)
        unsetAuth(client)
        return response
      } catch (error) {
        setTimeout(() => dispatch(resetLoginState()), 3000)
        throw error
      }
    },
  })
