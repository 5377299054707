export const REGISTER_API = '/public/register'
export const LOGIN_API = '/public/login'
export const VERIFY_API = '/public/verify'
export const FORGOTPASS_API = '/public/forgot-password'
export const RESETPASS_API = '/public/reset-password'
export const CONTACTUS_API = '/public/contactus'
export const USERINFO_API = '/private/user/info'
export const GENERATE_API = 'private/generatelink'
export const UPDATEPASSWORD_API = '/private/user/updatepassword'
export const USERBANDWIDTH_API = '/private/user/bandwidth'
export const HOST_STATUS_API = '/public/host-status'
export const INVOICE_API = '/private/user/create-invoice'
export const NEWS_API = '/public/news'
export const SAVED_LINKS_API = '/private/user/savelinks'
export const PAYMENT_STATUS_API = '/private/user/payment-status'
export const DOWNLOAD_INFO_API = '/public/download-info'
export const USER_SETTINGS = '/private/user/settings'
export const LOGOUT_API = '/private/user/logout'
export const DLHISTORY_API = '/private/user/dlhistory'
export const PAYMENT_OPTIONS_API = '/private/user/payment-options'
export const PREMIUM_KEY_API = '/private/user/redeem/premium-key'
export const FOLDER_LINKS_API = '/private/user/folder/links'
export const DELETE_ACCOUNT_API = '/private/user/delete/profile'

// Reducer key constants
export const HOST_STATUS = {
  key: 'hosts',
  api: HOST_STATUS_API,
}

export const PREMIUM_KEY = {
  key: 'premiumkey',
  api: PREMIUM_KEY_API,
}

export const PAYMENT_OPTIONS = {
  key: 'paymentoptions',
  api: PAYMENT_OPTIONS_API,
}

export const FORGOT_PASSWORD = {
  key: 'forgotpassword',
  api: FORGOTPASS_API,
  clearApiReponse: true,
}

export const DLHISTORY = {
  key: 'dlhistory',
  api: DLHISTORY_API,
}

export const DOWNLOAD_INFO = id => {
  return {
    key: 'downloadinfo',
    api: `${DOWNLOAD_INFO_API}/?id=${id}`,
  }
}

export const PAYMENT_STATUS = {
  key: 'paymentstatus',
  api: PAYMENT_STATUS_API,
  clearErrors: false,
}

export const RESETPASSWORD = {
  key: 'resetpass',
  api: RESETPASS_API,
}

export const CHANGEPASSWORD = {
  key: 'changepassword',
  api: UPDATEPASSWORD_API,
  clearApiReponse: true,
}

export const USERBANDWIDTH = {
  key: 'bandwidth',
  api: USERBANDWIDTH_API,
}

export const UPDATE_USERSETTINGS = {
  key: 'updatesettings',
  api: USER_SETTINGS,
  clearApiReponse: true,
}

export const CONTACTUS = {
  key: 'contact',
  api: CONTACTUS_API,
  clearApiReponse: true,
}

export const FOLDER_LINKS = {
  key: 'folderLinks',
  api: FOLDER_LINKS_API,
  clearErrors: true,
}

export const SITE_LINKS = {
  key: 'fetchedLinks',
  api: FOLDER_LINKS_API,
  clearErrors: true,
}

export const DELETE_ACCOUNT = {
  key: 'deleteAccount',
  api: DELETE_ACCOUNT_API,
  clearErrors: true,
}
