import React from 'react'
import Cookie from 'js-cookie'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'

import { loadState, saveState } from './src/localStorage/sync'
import apiClient from './src/axios'

import { setSync } from './src/reducers/app'

import combineReducers from './src/reducers'
import clientMiddleware from './src/reducers/middlwares/client'
import userMiddleWare from './src/reducers/middlwares/user'

import socket from './src/socket'

import Layout from './src/components/layout'

import 'bootstrap/dist/css/bootstrap.min.css'
import './src/components/layout.scss'

socket.on('hey-reload', () => window && window.location.reload())

const getAuth = Cookie.get('Authorization')

const showDevTools = () => {
  try {
    if (
      process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined'
    ) {
      return (
        (window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()) ||
        compose()
      )
    }
    return a => a
  } catch (error) {
    console.warn('Please install redux devtools extension')
  }
}

const thunk = store => next => action =>
  typeof action === 'function'
    ? action(store.dispatch, store.getState())
    : next(action)

const middlwares = [
  thunk,
  clientMiddleware(apiClient(getAuth)),
  userMiddleWare(),
]

const store = createStore(
  combineReducers,
  loadState(),
  compose(applyMiddleware(...middlwares), showDevTools())
)

store.subscribe(async () => {
  const { login, user } = store.getState()
  await saveState({
    login,
    user,
  })
})

socket.on('connect', () => {
  const {
    app: {
      app: { sync },
    },
  } = store.getState()
  if (!sync) store.dispatch(setSync())
  socket.emit('online')
  console.log('connected to stream')
})

socket.on('disconnect', () => {
  socket.emit('online')
  console.log('disconnected from stream')
})

/* trigger action when syncing is done */

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, props }) => {
  return (
    <Provider store={store}>
      <Layout {...props}>{element}</Layout>
    </Provider>
  )
}
